import env from '../../sdf-env.json';

const GRAFANA_WINDMILL_URL = 'https://grafana.smartdatafusion.jp';
const GRAFANA_SOLAR_URL = 'https://grafana-solar.smartdatafusion.jp';

export const windmillUrls = {
  management:
    process.env.REACT_APP_URL_WIND_MANAGEMENT ?? `${GRAFANA_WINDMILL_URL}/d/6Ge-r--7k/huamuxuan-ze?orgId=1`,
  operation:
    process.env.REACT_APP_URL_WIND_OPERATION ?? `${GRAFANA_WINDMILL_URL}/d/S8Vmlob7k/quan-ti-yun-yong-zhuang-kuang-_huamua?orgId=1&refresh=5s`,
  birdDetection:
    process.env.REACT_APP_URL_WIND_BIRD_DETECT ?? `${GRAFANA_WINDMILL_URL}/d/9PncyNB7z/niao-kaunto_huamua-1hao-ji?orgId=1&refresh=5s`,
};

export const solarUrls = {
  management:
    process.env.REACT_APP_URL_SOLAR_MANAGEMENT ?? `${GRAFANA_SOLAR_URL}/d/CjTiUfn4z/suo-you-saitoquan-ti-ba-wo?orgId=1`,
  siteComparison:
    process.env.REACT_APP_URL_SOLAR_SITE_COMPARISON ?? `${GRAFANA_SOLAR_URL}/d/F2g0wNnIz/saitojian-bi-jiao-_zhong-yao-fa-dian-zhi-biao?orgId=1`,
  electricEnergyAnalysis:
    process.env.REACT_APP_URL_SOLAR_ENERGY_ANALYSIS ?? `${GRAFANA_SOLAR_URL}/d/b_EihXQIk/fa-dian-liang-chai-yi-fen-xi-_-saito-lan?orgId=1`,
  imbalanceAnalysis:
    process.env.REACT_APP_URL_IMBALANCE_ANALYSIS ?? `${GRAFANA_SOLAR_URL}/d/af5e683b-b6ef-4f3f-bf27-ab1eaa691a9d/44Kk44Oz44OQ44Op44Oz44K55YiG5p6Q77yI5pyI5qyh77yJ?orgId=1`,
  electricEnergyPerformance:
    process.env.REACT_APP_URL_SOLAR_ENERGY_PERFORMANCE ?? `${GRAFANA_SOLAR_URL}/d/c4651cc8-575e-4f14-84ea-a9a1c1569dd8/367dce4c-2ed7-5331-950a-29e03d67aa95?orgId=1`,
};

export const powerUrls = {
  management:
    process.env.REACT_APP_URL_POWER_MANAGEMENT ?? `${GRAFANA_SOLAR_URL}/d/9wWL_6yVz/dian-yuan-tong-he-datsushiyubodo_yue-bie?orgId=1`,
};

export const cogniteChartUrl = process.env.REACT_APP_URL_SOLAR_CHART ?? 'https://nttcom.fusion.cognite.com/nttae01/charts?cluster=asia-northeast1-1.cognitedata.com';

export const roadManagementUrl = `${env.uploadOrigin}/sdf-road-management/wwwroot/`;
