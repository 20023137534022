// システム予約文字列の設定

/**
 * assetのMetadataのシステムで使用する項目一覧
 */
const assetReservedKeyList: string[] = [
  'assetType',
  'topflg',
  'managementNo',
  'isPyranometer',
  'electricity_area',
];

/**
 * fileのMetadataのシステムで使用する項目一覧
 */
const fileReservedKeyList: string[] = [];

/**
 * eventのMetadataのシステムで使用する項目一覧
 */
const eventReservedKeyList: string[] = [];

export {
  assetReservedKeyList,
  fileReservedKeyList,
  eventReservedKeyList,
};
